function setEqualHeight() {
    //$(".prva-pozdrav-enako").matchHeight();
    $(".news-list-012 .news-image-wrapper").matchHeight();
    $(".news-list-012 .news-content-wrapper").matchHeight();
}
$(window).on("load",function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height",maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height","");
            $('#main-menu').css("height","");
        });
    }
});

$(function() {
    $("#main-menu-fixed").smartmenus({
        isPopup: false,
        mainMenuSubOffsetX: 0,
        mainMenuSubOffsetY: 0,
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: -8,
        subMenusMinWidth: "10em",
        subMenusMaxWidth: "20em",
        keepInViewport: true,
        keepHighlighted: false,
        markCurrentItem: false,
        markCurrentTree: false
    });
    /*
    $("#main-menu").on("click.smapi", function(e, item) {
        var $item = $(item);
        if($item.attr("href") === "#") {
            e.preventDefault();
            $('#main-menu').smartmenus('itemActivate', $item);
            return false;
        }
    });
    */
    $("#main-menu-fixed").on('show.smapi', function(e, menu) {
        var $menu = $(menu),
            arslunaCenterLevel2 = true;
        if (arslunaCenterLevel2) {
            // check just first-level subs
            if ($menu.dataSM('level') == 2) {
                var obj = $(this).data('smartmenus'),
                    $item = $menu.dataSM('parent-a'),
                    itemW = obj.getWidth($item),
                    menuW = obj.getWidth($menu),
                    menuX = (itemW - menuW) / 2;
                // keep supporting keepInViewport
                if (obj.opts.keepInViewport) {
                    var $win = $(window),
                        winX = $win.scrollLeft(),
                        winW = obj.getViewportWidth(),
                        itemX = $item.offset().left,
                        absX = itemX + menuX;
                    if (absX < winX) {
                        menuX += winX - absX;
                    } else if (absX + menuW > winX + winW) {
                        menuX += winX + winW - menuW - absX;
                    }
                }
                $menu.css('margin-left', menuX);
                if ($menu.dataSM('ie-shim')) {
                    $menu.dataSM('ie-shim').css('margin-left', menuX);
                }
            }
        }
    });

    $('#main-menu-state-fixed').on("change",function(e) {
        var $menu = $('#main-menu-fixed');
        var maxHeight,
            skelBreakpoints = skel.vars.stateId;
        if (this.checked) {
            $menu.hide().slideDown(250, function() {
                $menu.css('display', '');
                if (skelBreakpoints.search("/medium") > -1) {
                    maxHeight = 0.95 * ($(window).height() - ($menu.offset().top - $(window).scrollTop()));
                    $menu.css("max-height", maxHeight + "px");
                    if ($menu.outerHeight() > maxHeight) {
                        $menu.css("height", maxHeight + "px");
                    }
                }
            });
        } else {
            $menu.show().slideUp(250, function() {
                $menu.css('display', '');
                $menu.css("max-height", "");
                $menu.css("height", "");
            });
        }
    });

    // $('#prva-mnenja').parallax({imageSrc: '/css/alpski-cvet.si/images/ozadje_mnenja.jpg'});
    // $('#razpolozljivost').parallax({imageSrc: '/css/alpski-cvet.si/images/ozadje-razpolozljivost.jpg'});
});

var headerMainFixedVisible = false;
$(window).on('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        viewport = $(window).width(),
        scroll_limit_1 = 420;
    switch (current_breakpoint) {
        case "xlarge":
            scroll_limit_1 = 400;
            break;
        case "large":
            scroll_limit_1 = 420;
            break;
        case "medium":
            scroll_limit_1 = 50;
            break;
        case "small":
            scroll_limit_1 = 50;
            break;
        case "xsmall":
            scroll_limit_1 = 90;
            break;
        default:
            scroll_limit_1 = 160;
            break;
    }
    /* Izračunljive vrednosti */
    if (current_breakpoint_num > 0) {
        if ($(window).scrollTop() < scroll_limit_1) {
            if (headerMainFixedVisible === true) {
                headerMainFixedVisible = false;
                $("#body-page-1 #header-main-fixed-wrapper").effect("slide", {
                    mode: "hide",
                    direction: "up"
                }, 500);
            }
        } else {
            if (headerMainFixedVisible === false) {
                console.log("show", headerMainFixedVisible);
                headerMainFixedVisible = true;
                $("#body-page-1 #header-main-fixed-wrapper").effect("slide", {
                    mode: "show",
                    direction: "up"
                }, 500);
            }
        }
    }
});
